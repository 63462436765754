import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function AssetzReadMoreLess() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Assetz Developers</h4>
            
            <p className="mb-0">
                {/* <h6>VISION THAT DRIVES US FORWARD</h6> */}
                <p className="AboutExpoPara">Founded in 2006, Assetz Property Group is one of the top real estate property developers in Bangalore, India. They are the front-runner amidst multinational developers in India with over 10 million square feet under development. Assetz, headquartered in Singapore, is a multi-faceted real estate development and asset management company with four business verticals: Commercial, Residential, Warehousing and Fund Management.</p>

                {/* <h6>MISSION THAT WE’VE TAKEN OVER</h6> */}
                <p className="AboutExpoPara">'Better Design' underpinned by meticulous research is deeply ingrained in the Assetz narrative, and evident in all their properties in Bangalore. This principle is reflected in every facet of construction – from the master plan, development strategy, architecture, location, property management; down to every hinge, tile and square inch.</p>
            </p>

            {isShowMore && (
                <p className="mb-0">
                    {/* <h6>Values</h6> */}
                    <p className="AboutExpoPara">Assetz has established itself as one of the leading names amongst the builders and developers in Bangalore. They have built two world-class IT tech parks in Bangalore: Vrindavan Tech Village (now Embassy Tech Village) - India’s first Platinum LEED certified IT SEZ, and Global Technology Park. And, its residential portfolio includes ten projects in prominent locations across Bangalore. The Assetz Fund Management vertical is a leading performer in the real estate fund management industry generating high alpha returns for its investors compared to its peers.</p>
                    {/* <p className="AboutExpoPara">Whenever we do work, we do it with the utmost sincerity. We keep everything transparent and always stand by the word we’ve given to our stakeholders.</p>
                    <p className="AboutExpoPara">We’re always driven by the fundamental human feeling in our hearts. We listen to and strive to understand our customers as well the needs of the society.</p>
                    <p className="AboutExpoPara">We create innovative solutions using our forward-thinking mindset. We always search out of the box to find the kind of perfection that shows vision.</p>

                    <h6>A JOURNEY TOWARDS IMMORTALITY</h6> */}
                    <p className="AboutExpoPara">Assetz is a preferred partner of private equity funds completing more than 13 private equity investments, and delivering nine exits to date. A stellar track record has earned Assetz a reputation for professionalism and for challenging conventions through innovation. The company is on an upward growth trajectory and is one of the fastest growing real estate developers in the country.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default AssetzReadMoreLess;